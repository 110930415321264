import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_ENV } from '$env/static/public';

if (PUBLIC_ENV != 'localhost') {
	setTimeout(() => {
		Sentry.init({
			dsn: 'https://2907e7e0eeddcaec4acbda2dc107ed3e@o4506316499451905.ingest.sentry.io/4506368286982144',
			tracesSampleRate: 1.0,

			// This sets the sample rate to be 10%. You may want this to be 100% while
			// in development and sample at a lower rate in production
			replaysSessionSampleRate: 0.1,

			// If the entire session is not sampled, use the below sample rate to sample
			// sessions when an error occurs.
			replaysOnErrorSampleRate: 1.0,

			// If you don't want to use Session Replay, just remove the line below:
			integrations: [new Replay()],
			environment: PUBLIC_ENV
		});
	}, 100);
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
